import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { BaseClickableIcon } from "../../../../../../components/Icon/BaseClickableIcon.tsx";
import { Icon } from "../../../../../../components/Icon/Icon.tsx";
import { useOn } from "../../../../../../hooks/useOn.ts";
import { ImageTile } from "../../../../components/ImageTile.tsx";
import { useBoard } from "../../../../hooks/useBoard.ts";
import { useSelectedAssetUuid } from "../../../../hooks/useSelectedAssetUuid.ts";

export const ImageStripe = () => {
  const stripeParentRef = useRef<HTMLDivElement | null>(null);
  const [isUnfolded, setIsUnfolded] = useState(false);
  const [parentWidth, setParentWidth] = useState(32);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isHoveredRef = useRef(false);

  // XXX: the image stripe has css position "fixed" so it cannot inherit its
  //  parent's size. We need to get the parent's size in js.
  useEffect(() => {
    if (!stripeParentRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      setParentWidth(entries[0].contentRect.width);
    });
    resizeObserver.observe(stripeParentRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <div className="w-full flex-row-center" ref={stripeParentRef}>
      <div
        className="fixed bottom-0"
        style={{
          maxWidth: `${Math.min(800, parentWidth - 32)}px`,
        }}
      >
        <div
          className={classNames(
            "transition-transform duration-300 ease-out",
            isUnfolded ? "scale-100" : "scale-95 translate-y-[calc(100%-26px)]",
            "pb-400",
          )}
          onMouseEnter={() => {
            setIsUnfolded(true);
            isHoveredRef.current = true;
          }}
          onMouseLeave={() => {
            setIsUnfolded(false);
            isHoveredRef.current = false;
          }}
        >
          <div
            className={classNames(
              "pt-150 pb-050 rounded-300 bg-surface-primary-rest shadow-200 min-w-[400px] w-full flex-col overflow-hidden",
            )}
          >
            <div className="flex-row-center gap-100">
              <Icon name="ChevronUp" rotate={isUnfolded ? 180 : 0} size="sm" />
              <div className="label-md-semibold text-secondary">All images</div>
            </div>
            <ImageStripeContent
              onAssetSelectionChange={() => {
                if (!isHoveredRef.current) {
                  setIsUnfolded(true);
                  if (timeoutRef.current) clearTimeout(timeoutRef.current);
                  timeoutRef.current = setTimeout(() => {
                    setIsUnfolded(false);
                  }, 800);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ImageStripeContent = ({
  onAssetSelectionChange,
}: {
  onAssetSelectionChange: () => void;
}) => {
  const { board } = useBoard();
  const { selectedAssetUuid, setSelectedAssetUuid } = useSelectedAssetUuid();

  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);

  useEffect(() => {
    if (
      selectedAssetUuid &&
      board.assets.findIndex((asset) => asset.uuid === selectedAssetUuid) !==
        selectedAssetIndex
    ) {
      setSelectedAssetIndex(
        board.assets.findIndex((asset) => asset.uuid === selectedAssetUuid),
      );
    }
  }, [board.assets, selectedAssetIndex, selectedAssetUuid]);

  useEffect(() => {
    document
      .getElementById(selectedAssetUuid ?? "")
      ?.scrollIntoView({ inline: "center", behavior: "instant" });
  }, [selectedAssetUuid]);

  const selectPreviousAsset = () => {
    if (selectedAssetIndex > 0) {
      setSelectedAssetIndex((index) => index - 1);
      setSelectedAssetUuid(board.assets[selectedAssetIndex - 1].uuid);
    }
    onAssetSelectionChange();
  };
  const selectNextAsset = () => {
    if (selectedAssetIndex < board.assets.length - 1) {
      setSelectedAssetIndex((index) => index + 1);
      setSelectedAssetUuid(board.assets[selectedAssetIndex + 1].uuid);
    }
    onAssetSelectionChange();
  };

  useOn("keydown", ({ key }) => {
    if (key === "ArrowLeft") selectPreviousAsset();

    if (key === "ArrowRight") selectNextAsset();
  });

  return (
    <div className="p-100 flex-row">
      <BaseClickableIcon
        name="ChevronLeft"
        className="p-0"
        size="sm"
        onClick={() => {
          selectPreviousAsset();
        }}
        disabled={selectedAssetIndex === 0}
      />
      <div className="relative flex-fill">
        <div className="absolute left-0 top-0 h-full w-100 z-10 bg-gradient-to-r from-[var(--grey-1)]" />
        <div id="image-stripe" className="w-full flex-row overflow-x-auto">
          <div className="pl-100" />
          {board.assets.map((asset) => (
            <div
              key={asset.uuid}
              id={asset.uuid}
              className={classNames(
                "p-[1px] rounded-200 border-050 bg-surface-secondary-rest",
                asset.uuid === selectedAssetUuid
                  ? "border-input-border-active"
                  : "border-[--grey-1] opacity-70 hover:opacity-100",
              )}
            >
              <div
                className="relative cursor-pointer h-[52px] w-[52px] rounded-150 overflow-hidden"
                onClick={() => {
                  setSelectedAssetUuid(asset.uuid);
                }}
              >
                {asset.deleted_at !== null && (
                  // FIXME: clean up the css colors
                  <Icon
                    name="Trash"
                    className="z-10 absolute bottom-0 right-0 p-050 bg-surface-error-primary-rest stroke-inverse-rest"
                  />
                )}
                <ImageTile
                  image={asset.content}
                  loading="lazy"
                  fit="contained"
                  loadingContentClassName="rounded-050 label-sm-default !p-0 !text-[8px]"
                  imageClassName="rounded-050"
                  srcDimension="small128"
                />
              </div>
            </div>
          ))}
          <div className="pl-100" />
        </div>
        <div className="absolute right-0 top-0 h-full w-100 bg-gradient-to-l from-[var(--grey-1)]" />
      </div>
      <BaseClickableIcon
        name="ChevronRight"
        size="sm"
        className="p-0"
        onClick={() => {
          selectNextAsset();
        }}
        disabled={selectedAssetIndex === board.assets.length - 1}
      />
    </div>
  );
};
