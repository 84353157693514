import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Badge } from "../../../../components/Badge/Badge.tsx";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { BaseClickableIcon } from "../../../../components/Icon/BaseClickableIcon.tsx";
import { Icon } from "../../../../components/Icon/Icon.tsx";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { BOARDS, EDIT_WORKSPACE, WORKSPACES } from "../../../../routes.ts";
import { addSearchParamsToPath } from "../../../../utils/url.ts";
import { useBoard } from "../../hooks/useBoard.ts";
import { useDeleteAssetOnBoard } from "../../hooks/useDeleteAssetOnBoard.ts";
import { ImageDownloadButton } from "../../ImageDownload/ImageDownloadButton.tsx";
import type { BoardAsset } from "../../types.ts";
import { ImageTile } from "../ImageTile.tsx";

export const MasonryAssetTile = memo(
  ({
    asset,
    favoriteTagUuid,
  }: {
    favoriteTagUuid?: string;
    asset: BoardAsset;
  }) => {
    const { workspace } = useWorkspace();
    const { board } = useBoard();
    const deleteAsset = useDeleteAssetOnBoard();
    const addTag = useAppMutation({
      path: "tags/add-assets",
      invalidate: [`boards/${board.uuid}`],
    }).mutation;
    const removeTag = useAppMutation({
      path: "tags/remove-assets",
      invalidate: [`boards/${board.uuid}`],
    }).mutation;
    const navigate = useNavigate();

    const isDeleteLoading = deleteAsset.isLoading;

    const addOrRemoveFavoriteTag = () => {
      if (asset.is_in_favorite && favoriteTagUuid) {
        removeTag.mutate({
          asset_uuids: [asset.uuid],
          tag_uuid: favoriteTagUuid,
        });
      } else {
        addTag.mutate({
          asset_uuids: [asset.uuid],
          tag_uuid: favoriteTagUuid,
        });
      }
    };

    return (
      <div
        className="w-full group relative overflow-clip"
        id={`AssetImageTile-${asset.uuid}`}
      >
        {asset.content.is_from_edit && (
          <Badge
            size="sm"
            color="transparentDark"
            label="Edited"
            className="z-10 absolute top-200 left-200"
          />
        )}
        {asset.deleted_at !== null && (
          <Icon
            name="Trash"
            className="z-10 absolute top-0 right-0 p-050 bg-surface-error-primary-rest stroke-inverse-rest"
          />
        )}
        {asset.content.status === "succeeded" && (
          <div className="absolute bottom-200 right-200 invisible group-hover:visible">
            <div className="flex-row gap-200">
              <BaseClickableIcon
                name="Heart"
                iconClassName={
                  asset.is_in_favorite
                    ? "fill-error-rest stroke-error-rest"
                    : ""
                }
                className="z-10 tile-action"
                tooltip={{
                  side: "top",
                  content: asset.is_in_favorite
                    ? "Remove from favorites"
                    : "Add to favorites",
                }}
                onClick={() => {
                  addOrRemoveFavoriteTag();
                }}
              />
              {!asset.deleted_at && (
                <BaseClickableIcon
                  name="Trash"
                  className="z-10 tile-action"
                  tooltip={{ side: "top", content: "Delete" }}
                  disabled={isDeleteLoading}
                  onClick={() => {
                    deleteAsset.mutate({
                      uuids: [asset.uuid],
                    });
                  }}
                />
              )}
              <ImageDownloadButton asset={asset} variant="icon" />
              <BaseButton
                className="z-10 tile-action hover:brightness-95 active:brightness-90 flex-row-center gap-200"
                onClick={() => {
                  navigate(
                    addSearchParamsToPath(
                      `${WORKSPACES}/${workspace.uuid}/${BOARDS}/${board.uuid}/${EDIT_WORKSPACE}`,
                      { assetUuid: asset.uuid },
                    ),
                  );
                }}
              >
                <Icon name="PencilLine" />
                Edit
              </BaseButton>
            </div>
          </div>
        )}
        <button
          type="button"
          className="w-full block"
          onClick={() => {
            navigate(
              addSearchParamsToPath(
                `${WORKSPACES}/${workspace.uuid}/${BOARDS}/${board.uuid}/${EDIT_WORKSPACE}`,
                { assetUuid: asset.uuid },
              ),
            );
          }}
        >
          {/* FIXME: add breakpoints to ask for smaller srcDimension if the screen is small */}
          {/* FIXME: in order to have good quality images without watermark we rely here on the fact that
           we fetch from the CDN without watermark, even if the size is above the limit in CF */}
          <ImageTile
            image={asset.content}
            fit="width"
            srcDimension="xlarge1024"
          />
          {asset.content.status === "succeeded" && (
            <div className="absolute left-0 bottom-0 w-full h-full invisible group-hover:visible bg-gradient-to-t from-[#1D1E21]/80 to-65%" />
          )}
        </button>
      </div>
    );
  },
);
MasonryAssetTile.displayName = "MasonryAssetTile";
