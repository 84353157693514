import { useEffect } from "react";
import { Navigate, Route, useLocation } from "react-router";
import { Routes } from "react-router-dom";
import { CREATE_WORKSPACE, EDIT_WORKSPACE } from "../../routes.ts";
import { AssetMenu } from "./AssetMenu/AssetMenu.tsx";
import { EditAssetDetails } from "./AssetMenu/EditAssetDetails.tsx";
import { CREATE_TOOLS, EDIT_TOOLS } from "./constants.ts";
import { Header } from "./Header/Header.tsx";
import { SettingsMenu } from "./SettingsMenu/SettingsMenu.tsx";
import { tagsFilterStore } from "./stores/tagsFilterStore.ts";
import { ToolMenu } from "./ToolMenu/ToolMenu.tsx";
import type { CreateTool, EditTool } from "./types.ts";
import { getSelectedTool } from "./utils/getSelectedTool.ts";
import { CreateWorkspace } from "./Workspaces/CreateWorkspace/CreateWorkspace.tsx";
import { generatePromptStore } from "./Workspaces/CreateWorkspace/Tools/GenerateView/stores/generatePromptStore.ts";
import { styleTransferBaseImageStore } from "./Workspaces/CreateWorkspace/Tools/StyleTransferView/stores/styleTransferBaseImageStore.ts";
import { transferPromptStore } from "./Workspaces/CreateWorkspace/Tools/StyleTransferView/stores/transferPromptStore.ts";
import { EditWorkspace } from "./Workspaces/EditWorkspace/EditWorkspace.tsx";
import { lastSelectedToolStore } from "./Workspaces/stores/lastSelectedToolStore.ts";

export const BoardContent = () => {
  const location = useLocation();
  useEffect(() => {
    tagsFilterStore.resetTagsFilter();
    styleTransferBaseImageStore.resetStyleTransferBaseImage();
    generatePromptStore.reset();
    transferPromptStore.reset();
    // XXX: On component unmount, we clear all the states to avoid any deprecated states when switching between boards,
    // such as useless query on previous selected style.
    return () => {
      tagsFilterStore.resetTagsFilter();
      styleTransferBaseImageStore.resetStyleTransferBaseImage();
      generatePromptStore.reset();
      transferPromptStore.reset();
    };
  }, []);

  useEffect(() => {
    const selectedTool = getSelectedTool();

    // FIXME: We use type assertion here because 'includes' does not make any
    //  type narrowing. Implementing a type guard would be cleaner.
    if (
      window.location.pathname.includes(CREATE_WORKSPACE) &&
      CREATE_TOOLS.includes(selectedTool as CreateTool)
    ) {
      lastSelectedToolStore.setLastCreateTool(selectedTool as CreateTool);
    }
    if (
      window.location.pathname.includes(EDIT_WORKSPACE) &&
      EDIT_TOOLS.includes(selectedTool as EditTool)
    ) {
      lastSelectedToolStore.setLastEditTool(selectedTool as EditTool);
    }
  }, [location]);

  const isSettingsMenuDisplayable =
    window.location.pathname.includes(CREATE_WORKSPACE) ||
    window.location.pathname.includes(EDIT_WORKSPACE);

  const isAssetMenuDisplayable =
    window.location.pathname.includes(EDIT_WORKSPACE);

  return (
    <div className="w-full h-full flex-row">
      <ToolMenu />
      {isSettingsMenuDisplayable && (
        <Routes>
          <Route path={CREATE_WORKSPACE} element={null} />
          <Route path={EDIT_WORKSPACE} element={null} />
          <Route path="*" element={<SettingsMenu />} />
        </Routes>
      )}
      <div className="flex-fill flex-col bg-primary">
        <Header />
        <div className="relative flex-fill flex-row">
          <Routes>
            <Route
              path={`${CREATE_WORKSPACE}/*`}
              element={<CreateWorkspace />}
            />
            <Route path={`${EDIT_WORKSPACE}/*`} element={<EditWorkspace />} />
            <Route
              path="*"
              element={<Navigate replace to={CREATE_WORKSPACE} />}
            />
          </Routes>
        </div>
      </div>
      {isAssetMenuDisplayable && <EditAssetDetails />}
      {isAssetMenuDisplayable && <AssetMenu />}
    </div>
  );
};
