import classNames from "classnames";
import { useRef, useState } from "react";
import { BaseClickableIcon } from "../Icon/BaseClickableIcon.tsx";
import { Icon, type IconName } from "../Icon/Icon.tsx";

export const TagInput = ({
  tagValues,
  onChange,
  placeholder,
  size,
  className,
  leftIconName,
  isDisabled = false,
}: {
  tagValues: string[];
  onChange: (tags: string[]) => void;
  className?: string;
  placeholder?: string;
  leftIconName?: IconName;
  size: "sm" | "md" | "lg";
  isDisabled?: boolean;
}) => {
  const [text, setText] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const removeTag = (index: number) => {
    const newTags = [...tagValues];
    newTags.splice(index, 1);
    onChange(newTags);
  };

  return (
    <div
      className={classNames(
        "flex-row group rounded-150 gap-150 border border-input-border-rest bg-input-surface-rest",
        isDisabled
          ? ""
          : "hover:border-input-border-hover hover:focus-within:border-input-border-active focus-within:border-input-border-active",
        isDisabled
          ? "bg-input-surface-disabled"
          : "focus-within:bg-input-surface-active",
        size === "sm"
          ? "px-150 py-[3px] min-h-700"
          : size === "md"
          ? "px-150 py-[7px] min-h-[44px]"
          : "px-200 py-[9px] min-h-1200",
        className,
      )}
      onClick={() => inputRef.current?.focus()}
    >
      {leftIconName && (
        <Icon
          name={leftIconName}
          size={size === "lg" ? "md" : "sm"}
          className={classNames(
            isDisabled
              ? ""
              : "stroke-secondary-rest group-hover:stroke-secondary-hover group-focus:stroke-secondary-active",
            size === "sm" ? "h-500" : size === "md" ? "h-700" : "h-800",
          )}
        />
      )}
      <div className="flex-fill flex-row items-center gap-150 flex-wrap">
        {tagValues.map((tag, i) => (
          <TagItem
            key={i}
            label={tag}
            size={size}
            onRemove={() => removeTag(i)}
            isDisabled={isDisabled}
          />
        ))}
        <input
          ref={inputRef}
          className={classNames(
            "flex-fill bg-inherit placeholder:text-secondary",
            size === "lg" ? "label-lg-default" : "label-md-default",
          )}
          value={text}
          onChange={(event) => {
            setText(event.target.value);
          }}
          onKeyDown={(event) => {
            event.stopPropagation();
            if (event.key === "Enter" && text) {
              if (
                tagValues.some(
                  (tag) => tag.toLowerCase() === text.toLowerCase(),
                )
              ) {
                return;
              }
              onChange([...tagValues, text]);
              setText("");
            } else if (
              event.key === "Backspace" &&
              !text &&
              tagValues.length > 0
            ) {
              removeTag(tagValues.length - 1);
            }
          }}
          onBlur={() => {
            if (text) {
              onChange([...tagValues, text]);
              setText("");
            }
          }}
          placeholder={
            isDisabled
              ? tagValues.length > 0
                ? undefined
                : "Not specified"
              : placeholder
          }
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

const TagItem = ({
  label,
  size,
  onRemove,
  isDisabled,
}: {
  label: string;
  size: "sm" | "md" | "lg";
  onRemove: () => void;
  isDisabled: boolean;
}) => (
  <div
    className={classNames(
      "max-w-full bg-surface-secondary-rest rounded-100 flex-row-center gap-100 truncate",
      size === "sm"
        ? "px-150 h-500 label-sm-semibold"
        : size === "md"
        ? "px-200 h-700 label-md-semibold"
        : "px-200 h-800 label-lg-semibold",
      isDisabled ? "text-disabled" : "",
    )}
  >
    {label}
    {!isDisabled && (
      <BaseClickableIcon
        name="X"
        disabled={isDisabled}
        size={size === "sm" ? "sm" : size === "md" ? "sm" : "md"}
        className={classNames(
          "rounded-050 hover:enabled:bg-button-surface-tertiary-hover active:enabled:bg-button-surface-tertiary-active",
          size === "sm"
            ? "h-400 w-400"
            : size === "md"
            ? "h-500 w-500"
            : "h-600 w-600",
        )}
        iconClassName={classNames(
          "stroke-secondary-rest hover:stroke-secondary-hover active:stroke-secondary-active",
          size === "sm"
            ? "stroke-md"
            : size === "md"
            ? "stroke-md"
            : "stroke-lg",
        )}
        onClick={onRemove}
      />
    )}
  </div>
);
