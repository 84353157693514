import classNames from "classnames";
import {
  CDNImage,
  type SrcDimension,
} from "../../../components/Image/CDNImage.tsx";
import type { AssetEditionContent, ImageContent } from "../types.ts";
import { LoadingTile } from "./LoadingTile.tsx";

export const ImageTile = ({
  image,
  loadingContentClassName,
  loading = "eager",
  fit,
  srcDimension,
  imageClassName,
}: {
  // FIXME: Create a base common Content type
  image: ImageContent | AssetEditionContent;
  fit: "width" | "height" | "contained";
  loadingContentClassName?: string;
  loading?: "eager" | "lazy";
  srcDimension: SrcDimension;
  imageClassName?: string;
}) => {
  if (image.status === "processing" || image.url === null) {
    return (
      <LoadingTile
        image={image}
        className={classNames(
          fit === "width"
            ? "w-full"
            : fit === "height"
            ? "h-full"
            : "w-full h-full",
          "aspect-square",
          loadingContentClassName,
        )}
      />
    );
  }

  return (
    <div
      className={
        fit === "width"
          ? "w-full"
          : fit === "height"
          ? "h-full"
          : "w-full h-full"
      }
    >
      <CDNImage
        className={
          fit === "width"
            ? "w-full"
            : fit === "height"
            ? "h-full"
            : "w-full h-full flex-row-center"
        }
        imageClassName={classNames(
          imageClassName,
          fit === "width"
            ? "w-full"
            : fit === "height"
            ? "h-full"
            : "max-w-full max-h-full",
        )}
        src={image.url}
        loading={loading}
        srcDimension={srcDimension}
        widthConstrained={fit === "width"}
        heightConstrained={fit === "height"}
      />
    </div>
  );
};
