import classNames from "classnames";
import { useEffect, useState } from "react";
import { BaseButton } from "../../../../../components/Button/BaseButton.tsx";
import { BaseClickableIcon } from "../../../../../components/Icon/BaseClickableIcon.tsx";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { CDNImage } from "../../../../../components/Image/CDNImage.tsx";
import { Spinner } from "../../../../../components/Spinner/Spinner.tsx";
import { useAppMutation } from "../../../../../http/useAppMutation.ts";
import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import { ImageTile } from "../../../components/ImageTile.tsx";
import { TOOL_DISPLAYABLE_NAMES } from "../../../constants.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import { useSelectedAsset } from "../../../hooks/useSelectedAsset.ts";
import { useUpdateAsset } from "../../../hooks/useUpdateAsset.ts";
import type {
  Asset,
  AssetEdition,
  AssetEditionContent,
  AssetEditionHistory,
  ImageContent,
} from "../../../types.ts";
import { overlayDisplayedContentUuidStore } from "../../EditWorkspace/stores/overlayDisplayedContentUuidStore.ts";
import { RightDetailsLayout } from "../RightDetailsLayout.tsx";

export const EditionHistory = ({
  onCloseButtonClick,
}: {
  onCloseButtonClick: () => void;
}) => {
  const { selectedAsset } = useSelectedAsset();

  const { data: editionHistory } = useAppQuery<AssetEditionHistory>({
    queryKey: selectedAsset?.uuid
      ? `assets/${selectedAsset.uuid}/history`
      : null,
    enabled: !!selectedAsset,
  });

  return (
    <RightDetailsLayout
      name="Edition history"
      onCloseButtonClick={onCloseButtonClick}
    >
      {selectedAsset && editionHistory && (
        <EditionHistoryContent
          selectedAsset={selectedAsset}
          editionHistory={editionHistory}
        />
      )}
    </RightDetailsLayout>
  );
};

const EditionHistoryContent = ({
  selectedAsset,
  editionHistory,
}: {
  selectedAsset: Asset;
  editionHistory: AssetEditionHistory;
}) => {
  useEffect(() => {
    overlayDisplayedContentUuidStore.clearOverlayDisplayedContentUuid();
    return () =>
      overlayDisplayedContentUuidStore.clearOverlayDisplayedContentUuid();
  }, []);

  return (
    <div className="px-200 py-300">
      {editionHistory.editions
        .sortDesc((it) => it.created_at)
        .map((edition, index) => (
          <Edition
            key={edition.uuid}
            edition={edition}
            selectedContent={selectedAsset.content}
            isOriginal={index === editionHistory.editions.length - 1}
          />
        ))}
    </div>
  );
};

const Edition = ({
  edition,
  selectedContent,
  isOriginal,
}: {
  edition: AssetEdition;
  selectedContent: ImageContent;
  isOriginal: boolean;
}) => {
  const editionSelectedContent = edition.contents.find(
    (content) => content.uuid === selectedContent.uuid,
  );
  const updateAsset = useUpdateAsset();
  const isSelected = !!editionSelectedContent;
  const [isUnfolded, setIsUnfolded] = useState(isSelected);
  return (
    <div className="flex-col">
      <div className="pr-150 flex-row items-center gap-200">
        <BaseClickableIcon
          name="ChevronRight"
          size="sm"
          rotate={isUnfolded ? 90 : 0}
          onClick={() => setIsUnfolded((unfolded) => !unfolded)}
          iconClassName={classNames(
            "stroke-lg",
            isSelected ? "stroke-button-primary-rest" : "",
          )}
          className={classNames(
            "rounded-full p-100",
            isSelected
              ? "bg-surface-inverse-rest hover:bg-surface-inverse-hover"
              : "bg-surface-secondary-rest hover:bg-surface-secondary-hover",
          )}
        />
        <div className="flex-fill body-md-semibold">
          {isOriginal
            ? "Original"
            : edition.contents[0].generation_model_type
            ? TOOL_DISPLAYABLE_NAMES[edition.contents[0].generation_model_type]
            : "Other"}
        </div>
        {editionSelectedContent && (
          <EditionThumbnail url={editionSelectedContent.url} />
        )}
      </div>
      <div className="flex-row gap-200">
        <div className={classNames("mx-300", isOriginal ? "" : "border-l")} />
        <div className="flex-fill pb-300">
          {isUnfolded && (
            <div className="py-100 grid grid-cols-2 gap-050">
              {edition.contents.map((content) => (
                <EditionContentTile
                  key={content.uuid}
                  content={content}
                  isSelected={selectedContent.uuid === content.uuid}
                  onClick={() => updateAsset(content.uuid)}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const thumbnailClassName = "h-600 w-600 rounded-150";
const EditionThumbnail = ({ url }: { url: string | null }) =>
  url ? (
    <CDNImage
      src={url}
      className={thumbnailClassName}
      imageClassName="object-cover h-full w-full"
      srcDimension="thumbnail64"
    />
  ) : (
    <div
      className={classNames(
        "flex-row-center bg-surface-secondary-active",
        thumbnailClassName,
      )}
    >
      <Spinner size="sm" />
    </div>
  );

const EditionContentTile = ({
  content,
  isSelected,
  onClick,
}: {
  content: AssetEditionContent;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <div
    className={classNames(
      "relative group w-[85px] h-[85px] border-050 rounded-200",
      isSelected ? "border-input-border-active" : "border-[--grey-1]",
    )}
    onMouseEnter={() => {
      overlayDisplayedContentUuidStore.setOverlayDisplayedContentUuid(
        content.uuid,
      );
    }}
    onMouseLeave={() => {
      overlayDisplayedContentUuidStore.clearOverlayDisplayedContentUuid();
    }}
  >
    <BaseButton
      key={content.uuid}
      className={classNames(
        "p-050 w-full h-full rounded-200 overflow-hidden bg-surface-secondary-rest",
        isSelected
          ? ""
          : "opacity-70 hover:opacity-100 hover:bg-surface-secondary-hover",
      )}
      onClick={onClick}
    >
      <ImageTile
        image={content}
        loading="lazy"
        fit="contained"
        srcDimension="medium256"
        imageClassName="rounded-150"
        loadingContentClassName="rounded-150"
      />
    </BaseButton>
    <div className="absolute top-100 right-100 invisible group-hover:visible">
      <CopyContentAsAssetButton contentUuid={content.uuid} />
    </div>
  </div>
);

const CopyContentAsAssetButton = ({ contentUuid }: { contentUuid: string }) => {
  const { board } = useBoard();

  // FIXME: When creating asset from existing content, we duplicate also the whole history of the asset. This could be
  //  fixed by saving in the asset table an original content from which computing the history.
  const { mutate: createAsset } = useAppMutation({
    path: "assets/create",
    invalidate: [`boards/${board.uuid}`],
  }).mutation;

  return (
    <ClickableIcon
      name="CopyPlus"
      variant="inverse-primary"
      size="sm"
      tooltip={{
        side: "bottom",
        content: "Save copy as new asset",
      }}
      onClick={() =>
        createAsset({
          board_uuid: board.uuid,
          content_uuid: contentUuid,
        })
      }
    />
  );
};
