import classNames from "classnames";
import { useMemo, useState } from "react";
import { BaseButton } from "../../../../../components/Button/BaseButton.tsx";
import { Button } from "../../../../../components/Button/Button.tsx";
import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { TextField } from "../../../../../components/TextField/TextField.tsx";
import { useAppMutation } from "../../../../../http/useAppMutation.ts";
import { FAVORITE_TAG_NAME } from "../../../constants.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import type { Asset } from "../../../types.ts";

export const AssetTagsSection = ({ asset }: { asset: Asset }) => {
  const { board } = useBoard();
  const [newTagName, setNewTagName] = useState<string>("");

  const addAssetsToTag = useAppMutation({
    path: "tags/add-assets",
    invalidate: [`assets/${asset.uuid}`, `boards/${board.uuid}`],
  }).mutation;
  const removeAssetsFromTag = useAppMutation({
    path: "tags/remove-assets",
    invalidate: [`assets/${asset.uuid}`, `boards/${board.uuid}`],
  }).mutation;

  const addNewTag = useAppMutation({
    path: "boards/add-tag",
    invalidate: [`boards/${board.uuid}`],
    onSuccess: (response: { data: { tag_uuid: string } }) =>
      addAssetToTag({ tagUuid: response.data.tag_uuid }),
  }).mutation;

  const createTag = () => {
    addNewTag.mutate({ board_uuid: board.uuid, tag_name: newTagName });
    setNewTagName("");
  };

  const addAssetToTag = ({ tagUuid }: { tagUuid: string }) => {
    addAssetsToTag.mutate({
      asset_uuids: [asset.uuid],
      tag_uuid: tagUuid,
    });
  };

  const tagsFilterSelected = useMemo(
    () =>
      board.tags
        // Set Favorite at the first position for display purpose
        .filter((tag) => tag.name === FAVORITE_TAG_NAME)
        .concat(board.tags.filter((tag) => tag.name !== FAVORITE_TAG_NAME))
        .map((tag) => ({
          isSelected: asset.tags.some((it) => it.uuid === tag.uuid),
          tag,
        })),
    [board.tags, asset],
  );

  return (
    <div className="flex-col gap-200 text-primary">
      <div className="flex-row flex-wrap gap-100">
        {tagsFilterSelected.map((tag) => (
          // FIXME: Define and use secondary button instead
          <BaseButton
            key={tag.tag.uuid}
            className={classNames(
              tag.isSelected
                ? "text-white bg-surface-inverse-rest"
                : "bg-surface-secondary-rest",
              "rounded-100 label-md-semibold text-center px-200 py-100 hover:bg-surface-secondary-hover hover:text-white",
            )}
            onClick={() => {
              if (tag.isSelected) {
                removeAssetsFromTag.mutate({
                  asset_uuids: [asset.uuid],
                  tag_uuid: tag.tag.uuid,
                });
              } else {
                addAssetToTag({ tagUuid: tag.tag.uuid });
              }
            }}
            name={tag.tag.name}
          >
            {tag.tag.name === FAVORITE_TAG_NAME ? (
              <div className="pb-100 pt-050">
                <Icon
                  name="Heart"
                  size="sm"
                  className={classNames(
                    tag.isSelected ? "stroke-inverse-rest" : "",
                  )}
                />
              </div>
            ) : (
              tag.tag.name
            )}
          </BaseButton>
        ))}
      </div>
      <div className="flex-row items-center justify-end gap-100">
        <TextField
          value={newTagName}
          onChange={setNewTagName}
          size="md"
          placeholder="Create new tag"
        />
        <Button
          onClick={() => {
            if (newTagName.isNotBlank()) createTag();
          }}
          loading={addNewTag.isLoading}
          variant="secondary"
          disabled={newTagName.isBlank()}
        >
          Add
        </Button>
      </div>
    </div>
  );
};
