import classNames from "classnames";
import type { InputHTMLAttributes } from "react";
import { Icon, type IconName } from "../Icon/Icon.tsx";

// FIXME: implement left icon, error when needed
export const TextField = ({
  value,
  onChange,
  label,
  placeholder,
  size,
  className,
  inputClassName,
  rightIconName,
  rightSuffix,
  onKeyDown,
  ...rest
}: {
  value: string;
  onChange: (text: string) => void;
  className?: string;
  inputClassName?: string;
  label?: string;
  placeholder?: string;
  rightIconName?: IconName;
  rightSuffix?: string;
  size: "sm" | "md" | "lg";
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "size" | "onChange" | "value" | "onKeyDown"
>) => (
  <div className={classNames("flex-col", className)}>
    {label && (
      <div className={size === "lg" ? "body-lg-semibold" : "body-md-semibold"}>
        {label}
      </div>
    )}
    <div className="w-full relative flex-row items-center">
      <input
        className={classNames(
          TextInputClassName,
          // XXX: we use padding instead of height to avoid https://github.com/facebook/react-native/issues/28078
          size === "sm"
            ? "px-150 py-[3px] label-md-default"
            : size === "md"
            ? "px-150 py-[7px] label-md-default"
            : "px-200 py-[9px] label-lg-default",
          rightIconName ?? rightSuffix
            ? size === "lg"
              ? "pr-800"
              : "pr-600"
            : undefined,
          inputClassName,
        )}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        onKeyDown={(event) => {
          event.stopPropagation();
          onKeyDown?.(event);
        }}
        placeholder={placeholder}
        {...rest}
      />
      {(rightIconName ?? rightSuffix) && (
        <div className="absolute right-200 flex-row-center">
          {rightSuffix && (
            <div
              className={
                size === "sm"
                  ? "label-md-default"
                  : size === "md"
                  ? "label-md-default"
                  : "label-lg-default"
              }
            >
              {rightSuffix}
            </div>
          )}
          {rightIconName && (
            <Icon
              name={rightIconName}
              size={size === "lg" ? "md" : "sm"}
              className="stroke-secondary-rest"
            />
          )}
        </div>
      )}
    </div>
  </div>
);

export const TextInputClassName = classNames(
  "flex-fill rounded-150 placeholder:text-secondary",
  "border border-input-border-rest",
  "hover:enabled:border-input-border-hover focus:enabled:border-input-border-active ",
  "bg-input-surface-rest hover:enabled:bg-input-surface-hover focus:enabled:bg-input-surface-active disabled:bg-input-surface-disabled",
);
