import classNames from "classnames";
import { BaseClickableIcon } from "../../../../../components/Icon/BaseClickableIcon.tsx";
import { CDNImage } from "../../../../../components/Image/CDNImage.tsx";
import { Spinner } from "../../../../../components/Spinner/Spinner.tsx";
import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import { notifier } from "../../../../../utils/notifier.ts";
import type { Style } from "../../../../types.ts";
import { useSelectedAsset } from "../../../hooks/useSelectedAsset.ts";
import type { ImageContent, StyleGenerationParams } from "../../../types.ts";
import { RightDetailsLayout } from "../RightDetailsLayout.tsx";
import { SectionDetails, TextItemSectionDetails } from "../SectionDetails.tsx";
import { GeneralInformationSection } from "./GeneralInformationSection.tsx";

const isNumber = (x: any): x is number => typeof x === "number";

export const InformationDetails = ({
  onCloseButtonClick,
  variant = "light",
}: {
  onCloseButtonClick: () => void;
  variant?: "light" | "dark";
}) => {
  const { selectedAsset: asset } = useSelectedAsset();

  return (
    <RightDetailsLayout
      name="Info"
      onCloseButtonClick={onCloseButtonClick}
      variant={variant}
    >
      {asset !== undefined && (
        <div
          className={classNames(
            "flex-col flex-grow ",
            variant === "dark"
              ? "divide-y-025 divide-border-inverse"
              : "divide-y-025 divide-border",
          )}
        >
          <GeneralInformationSection variant={variant} />
          <PromptInformationSection
            title="Brief"
            prompt={
              asset.content.generation_model_type === "brief_to_image"
                ? asset.content.generation_model_params.user_prompt
                : undefined
            }
            variant={variant}
          />
          <PromptInformationSection
            title="Prompt"
            prompt={
              asset.content.generation_model_type === "brief_to_image"
                ? asset.content.generation_model_params.generated_prompt
                : asset.content.generation_model_params?.user_prompt
            }
            variant={variant}
          />
          {asset.content.generation_model_type !== null && (
            <SectionDetails name="Models used" variant={variant}>
              <div className="flex-col justify-center divide-y">
                {asset.content.generation_model_params.styles.map(
                  (styleGenerationParams) => (
                    <StyleInformationSection
                      key={styleGenerationParams.uuid}
                      styleGenerationParams={styleGenerationParams}
                      variant={variant}
                    />
                  ),
                )}
              </div>
            </SectionDetails>
          )}
          <InitImageInformationSection
            image={asset.content}
            variant={variant}
          />
        </div>
      )}
    </RightDetailsLayout>
  );
};

const StyleInformationSection = ({
  styleGenerationParams,
  variant = "light",
}: {
  styleGenerationParams: StyleGenerationParams;
  variant?: "light" | "dark";
}) => {
  const loraScale = styleGenerationParams.scale;
  const { data: style, isLoading } = useAppQuery<Style>({
    queryKey: `styles/${styleGenerationParams.uuid}`,
  });

  return isLoading ? (
    <Spinner />
  ) : style ? (
    <div className="flex-col py-150">
      <div className="grid grid-cols-4 gap-200 w-fit pb-150">
        {style.training_images.slice(0, 4).map((it) => (
          <CDNImage
            key={it.uuid}
            className="h-full w-full aspect-square max-h-1600 max-w-1600"
            imageClassName="h-full w-full object-cover object-center rounded-100 border-025"
            src={it.url}
            srcDimension="small128"
          />
        ))}
      </div>
      {style.type && style.name && (
        <TextItemSectionDetails
          name={style.type.upperFirst()}
          value={style.name}
          variant={variant}
        />
      )}
      {isNumber(loraScale) && (
        <TextItemSectionDetails
          name="Model influence"
          value={String(Math.round(loraScale * 100)) + "%"}
          variant={variant}
        />
      )}
    </div>
  ) : null;
};

const PromptInformationSection = ({
  prompt,
  title,
  variant = "light",
}: {
  title: string;
  prompt?: string | null;
  variant?: "light" | "dark";
}) =>
  prompt !== undefined &&
  prompt !== null && (
    <SectionDetails
      name={title}
      variant={variant}
      nextToTitleComponent={
        <BaseClickableIcon
          name="Copy"
          iconClassName={
            variant === "dark" ? "stroke-inverse-rest" : "stroke-primary-rest"
          }
          className={classNames(
            "p-200 rounded-100",
            variant === "dark"
              ? "hover:bg-surface-inverse-hover"
              : "hover:bg-surface-primary-hover",
          )}
          onClick={() =>
            navigator.clipboard
              .writeText(prompt)
              .then(() => notifier.success("Copied to clipboard."))
          }
        />
      }
    >
      <div
        className={classNames(
          variant === "dark" ? "text-inverse-primary" : "text-primary",
        )}
      >
        {prompt ? prompt : "No prompt used"}
      </div>
    </SectionDetails>
  );

const InitImageInformationSection = ({
  image,
  variant = "light",
}: {
  image: ImageContent;
  variant?: "light" | "dark";
}) => {
  const initImageUuid =
    image.generation_model_type === "upscaler" ||
    image.generation_model_type === "generative_fill" ||
    image.generation_model_type === "style_transfer" ||
    image.generation_model_type === "image_to_image"
      ? image.generation_model_params.init_image_uuid
      : undefined;

  const { data: initImageData } = useAppQuery<{ url: string }>({
    queryKey: initImageUuid ? `contents/${initImageUuid}` : null,
  });

  return (
    initImageData?.url && (
      <SectionDetails name="Initial image" variant={variant}>
        <CDNImage
          src={initImageData.url}
          className="max-w-full"
          imageClassName="h-full w-full object-cover object-center"
          srcDimension="large512"
        />
      </SectionDetails>
    )
  );
};
