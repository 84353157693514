import classNames from "classnames";
import TextareaAutosize, {
  type TextareaAutosizeProps,
} from "react-textarea-autosize";
import { Icon, type IconName } from "../Icon/Icon.tsx";
import { TextInputClassName } from "../TextField/TextField.tsx";

export const Textarea = ({
  value,
  onChange,
  label,
  placeholder,
  size,
  className,
  inputClassName,
  rightIconName,
  rightSuffix,
  minRows,
  maxRows,
  onKeyDown,
  ...rest
}: {
  value: string;
  onChange: (text: string) => void;
  className?: string;
  inputClassName?: string;
  label?: string;
  placeholder?: string;
  rightIconName?: IconName;
  rightSuffix?: string;
  size: "sm" | "md" | "lg";
  maxRows?: number;
  minRows?: number;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
} & Omit<
  TextareaAutosizeProps,
  "size" | "onChange" | "value" | "onKeyDown"
>) => (
  <div className={classNames("flex-col", className)}>
    {label && (
      <div className={size === "lg" ? "body-lg-semibold" : "body-md-semibold"}>
        {label}
      </div>
    )}
    <div className="w-full relative flex-row items-center">
      <TextareaAutosize
        className={classNames(
          TextInputClassName,
          "resize-none",
          size === "sm"
            ? "p-150 label-md-default"
            : size === "md"
            ? "p-150 label-md-default"
            : "p-200 label-lg-default",
          rightIconName ?? rightSuffix
            ? size === "lg"
              ? "pr-800"
              : "pr-600"
            : undefined,
          inputClassName,
        )}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        placeholder={placeholder}
        minRows={minRows}
        maxRows={maxRows}
        onKeyDown={(event) => {
          event.stopPropagation();
          onKeyDown?.(event);
        }}
        {...rest}
      />
      {(rightIconName ?? rightSuffix) && (
        <div className="absolute right-200 flex-row-center">
          {rightSuffix && (
            <div
              className={
                size === "sm"
                  ? "label-md-default"
                  : size === "md"
                  ? "label-md-default"
                  : "label-lg-default"
              }
            >
              {rightSuffix}
            </div>
          )}
          {rightIconName && (
            <Icon
              name={rightIconName}
              size={size === "lg" ? "md" : "sm"}
              className="stroke-secondary-rest"
            />
          )}
        </div>
      )}
    </div>
  </div>
);
